function isSubsequence(sub: string, str: string) {
  let subIndex = 0;
  
  for (let char of str) {
    if (char === sub[subIndex]) {
      subIndex++;
    }
    if (subIndex === sub.length) {
      return true;
    }
  }
  
  return false;
}

export function filterArraySubsequence<T>(array: Array<T>, key: (value: T) => string, query: string) {
    query = query.toLowerCase()
    return array.filter(item => isSubsequence(query, key(item).toLowerCase()));
}