import "htmx.org";
import Alpine from 'alpinejs';
import persist from '@alpinejs/persist'
import {filterArraySubsequence} from "./filter"
import {percentageBackgroundColor, percentageForegroundColor, percentageFullColor} from "./standard_colors"
import {decodeB64Content} from "./util"
import myPlanningTable from "./my-planning-table";
import toastCard from "./toast-card";

window.Alpine = Alpine
window.filterArraySubsequence = filterArraySubsequence
window.percentageBackgroundColor = percentageBackgroundColor
window.percentageForegroundColor = percentageForegroundColor
window.percentageFullColor = percentageFullColor
window.decodeB64Content = decodeB64Content
window.myPlanningTable = myPlanningTable
window.toastCard = toastCard

console.groupCollapsed("Globals")
console.log(window.filterArraySubsequence)
console.log(window.percentageBackgroundColor)
console.log(window.percentageFullColor)
console.log(window.filterArraySubsequence)
console.log(window.decodeB64Content)
console.log(window.myPlanningTable)
console.log(window.toastCard)
console.groupEnd()

Alpine.plugin(persist)
 
Alpine.start();
