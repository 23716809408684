export default function myPlanningTable(contentB64, partTime) {
    const {
        headers,
        rows,
        absence: intern_weeks,
    }: {
        headers: {
            week: number,
            date_start: string,
            date_end: string,
        }[],
        rows: {
            pinned: boolean,
            modelId: number,
            task: string,
            notes: string,
            capacity: number[],
            deleted: boolean,
        }[],
        absence: number[],
    } = window.decodeB64Content(contentB64)

    return {
        headers,
        rows,
        newRowsIndex: rows.length,
        intern_weeks,
        addRow() {
            const capacity: number[] = []
            for (let i = 0; i < this.headers.length; i++) {
                capacity.push(0)
            }
            this.rows.push({ pinned: false, task: '', notes: '', capacity, deleted: false });
        },
        removeRow(index) {
            this.rows.splice(index, 1);
        },
        sumOfColumn(index) {
            const map_value = value => {
                const parsed = parseInt(value)
                if (parsed != parsed) {
                    return 0
                }
                return parsed
            }
            return this.rows.map(row => map_value(row.capacity[index])).reduce((partialSum, a) => partialSum + a, partTime + map_value(this.intern_weeks[index]))
        },
        rowIsEmpty(index) {
            if (index >= this.newRowsIndex || this.rows[index].deleted) {
                return false;
            }
            const row = this.rows[index]
            return !row.pinned && row.capacity.every(week => {
                return week != week || week == 0
            })
        },
        indicationColor(percentage) {
            if (percentage == 0) {
                return '';
            }
            if (percentage < 50) {
                return 'bg-green-500';
            }
            if (percentage < 75) {
                return 'bg-yellow-500';
            }
            if (percentage < 100) {
                return 'bg-orange-500';
            }
            return 'bg-red-500';
        },
    }
}
