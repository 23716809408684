export function percentageBackgroundColor(percentage: number) {
    if(percentage <= 0) {
        return "bg-white";
    }

    if(percentage <= 25) {
        return "bg-green-400";
    }

    if(percentage <= 50) {
        return "bg-yellow-400";
    }

    if(percentage <= 75) {
        return "bg-orange-400";
    }

    if(percentage <= 100) {
        return "bg-red-400";
    }

    return "bg-rose-800";
}

export function percentageForegroundColor(percentage: number) {
    if(percentage <= 100) {
        return "text-black"
    }
    
    return "text-white"
}

export function percentageFullColor(percentage: number) {
    return `${percentageBackgroundColor(percentage)} ${percentageForegroundColor(percentage)}`
}